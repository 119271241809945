<template>
  <v-card flat class="ma-3">

    <InfosCandidat ref="infoscandidat"></InfosCandidat>
    <infordv ref="infordv"></infordv>
    <AddCandidat ref="addcandidat" @candidatAdded="fetchCandidats()"></AddCandidat>
    <UpdateCandidat ref="updatecandidat" @candidatUpdated="fetchCandidats()"></UpdateCandidat>
    <UpdateRDV ref="updaterdv" @rdvUpdated="fetchCandidats()"></UpdateRDV>
    <DelCandidat ref="delcandidat" @candidatDeleted="fetchCandidats()"></DelCandidat>
    <add-candidat-button></add-candidat-button>

    <v-card height="110" class="elevation-3 rounded-xl pa-2 mb-3" flat color="#FFFEFE">

      <v-card class="d-flex flex-row mb-3" flat>

        <!-- Barre de recherche -->
        <v-text-field v-model="search" clearable background-color="#f4f4f4" class="rounded-pill mr-2" dense hide-details
          single-line label="Rechercher" outlined rounded>

          <template v-slot:append>
            <v-card @click="fetchCandidats()" flat class="pa-1 rounded-circle pos-button" color="#1b6b9a">
              <v-icon color="white">mdi-magnify</v-icon>
            </v-card>
          </template>
        </v-text-field>


        <v-btn-toggle v-model="toggle" mandatory rounded dense class="rounded-pill" color="mc2i" @change="changeToggle()">

          <v-btn value="OPE">OPE</v-btn>
          <v-btn value="FCT">FCT</v-btn>

        </v-btn-toggle>

      </v-card>

      <v-card class="d-flex align-center" flat>


        <!-- <v-btn rounded dense color="primary" class="rounded-xl elevation-3 mr-4" @click="toggleChasseFilter">
          {{ showChasseFilter ? 'Désactiver Chasse' : 'Afficher Chasse' }}
        </v-btn> -->
        <v-chip-group :key="key" v-model="selectedFilterChip" mandatory>


          <v-menu v-model="showChipMenu" :close-on-content-click="false" transition="fab-transition" rounded="xl"
            max-width="256" offset-y bottom>
            <template>
              <v-chip v-for="filter in activeFilters" :key="filter.text" class="rounded-pill">
              </v-chip>
            </template>
          </v-menu>
        </v-chip-group>

        <v-btn @click="exportData" :loading="exporting" :disabled="exporting" class="floating-export-btn" color="#0F9D58" v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(3) || userRoles.includes(4) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9) || userRoles.includes(10)" rounded small fab>
          <v-icon color="white">mdi-google-spreadsheet</v-icon>

        </v-btn>

        <v-btn-toggle rounded dense multiple disabled color="mc2i">
          <v-btn v-model="is_sans_rdv">
            Sans RDV
          </v-btn>
          <v-btn v-model="is_rdv1">
            RDV 1
          </v-btn>
          <v-btn v-model="is_rdv2">
            RDV 2
          </v-btn>
          <v-btn v-model="is_rdv3">
            RDV 3
          </v-btn>
          <v-btn v-model="is_rdv4">
            RDV 4
          </v-btn>
          <v-btn v-model="is_offre">
            Offre
          </v-btn>
        </v-btn-toggle>

      </v-card>
    </v-card>

    <v-data-table class="justify-center elevation-4 rounded-xl grey lighten-3" :items="candidats"
      :server-items-length="totalItems" :loading="loading" :server-search="true" :server-pagination="true"
      :items-per-page.sync="page_size" :page.sync="page" :server-items="fetchCandidats" :footer-props="{
        'itemsPerPageOptions': [250, 500, 750, 1000],
        'showCurrentPage': true,
        'show-first-last-page': true,
        'items-per-page-text': 'Rows per page :',
        'rows-per-page-text': 'Rows'
      }" no-data-text="Aucun candidat trouvé" no-results-text="Aucun candidat trouvé"
      loading-text="Chargment des candidats...">

      <template v-slot:header="header">
        <thead>
          <tr>
            <th v-for="header in headers" class="pa-2 text-center">
              {{ header.text }}
              <v-menu :close-on-content-click="false" rounded="xl" class="d-block" rigth offset-x>

                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">{{ shownFilterIcons(header.value) }}</v-icon>
                </template>

                <v-list>

                  <div v-if="header.value == candidatsFilters[0].value">
                    <v-text-field class="ps-3 pe-3" v-model="tacSearch" label="Recherche TAC" clearable @click:clear="tacSearch = null"></v-text-field>

                    <div class="overflow-y-auto ">
                      <v-card max-height="300" flat>
                        <v-checkbox class="ps-3 pe-3 ma-0" v-for="tac in filteredTAC" :key="tac" v-model="candidatsFilters[0].selected" :value="tac" :label="tac"></v-checkbox>
                      </v-card>
                    </div>
                  </div>

                  <v-date-picker v-if="header.value == candidatsFilters[1].value" v-model="candidatsFilters[1].selected"
                    value="2023-03-01" type="month" no-title multiple locale="fr-FR" width="228"><v-chip
                      variant="outlined" @click="clearDate(1)" class="mx-auto">Réinitialiser
                      filtre</v-chip></v-date-picker>

                      <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                            v-if="header.value == candidatsFilters[2].value" flat>

                        <v-text-field class="ps-3 pe-3" v-model="profilSearch" label="Recherche un emploi" clearable @click:clear="profilSearch = null"></v-text-field>
                        <v-checkbox class="ma-0" v-for="profil in filteredProfil" v-model="candidatsFilters[2].selected"
                          :value="profil" :label="profil" :key="profil"></v-checkbox>
                      </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[3].value" flat>
                    <v-checkbox class="ma-0" v-for="tEcole in champs.types_ecole" v-model="candidatsFilters[3].selected"
                       :value="tEcole" :label="tEcole"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[4].value" flat>
                    <v-checkbox class="ma-0" v-for="statut in champs.statuts" v-model="candidatsFilters[4].selected"
                       :value="statut" :label="statut"></v-checkbox>
                  </v-card>


                  <v-date-picker v-if="header.value == candidatsFilters[5].value" :first-day-of-week="1" v-model="candidatsFilters[5].selected"
                    no-title multiple range locale="fr-FR" width="228" @input="handleDateRangeChange"><v-chip
                      variant="outlined" @click="clearDate(5)" class="mx-auto">Réinitialiser
                      filtre</v-chip></v-date-picker>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[6].value" flat>
                    <v-checkbox class="ma-0" v-for="labelEntite in champs.label_entite"
                      v-model="candidatsFilters[6].selected"  :value="labelEntite"
                      :label="labelEntite"></v-checkbox>
                  </v-card>
                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[10].value" flat>
                    <v-checkbox v-model="candidatsFilters[10].selected" :value="1" :label="'Oui'"
                      :key="'Oui'"></v-checkbox>
                    <v-checkbox v-model="candidatsFilters[10].selected" :value="'null'" :label="'Non'"
                      :key="'Non'"></v-checkbox>
                  </v-card>
                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[11].value" flat>
                    <v-checkbox v-model="candidatsFilters[11].selected" :value="1" :label="'Oui'"
                      :key="'Oui'"></v-checkbox>
                    <v-checkbox v-model="candidatsFilters[11].selected" :value="'null'" :label="'Non'"
                      :key="'Non'"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[7].value" flat>
                    <v-checkbox class="ma-0" v-for="pole in champs.poles" v-model="candidatsFilters[7].selected"
                       :value="pole" :label="pole"></v-checkbox>
                  </v-card>
                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[9].value" flat>
                    <v-checkbox class="ma-0" v-for="pole in champs.sources_chasse" v-model="candidatsFilters[9].selected"
                      :value="pole" :label="pole"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[8].value" flat>
                    <v-checkbox class="ma-0" v-for="labelContrat in champs.label_contrat"
                      v-model="candidatsFilters[8].selected"  :value="labelContrat"
                      :label="labelContrat"></v-checkbox>
                  </v-card>
                </v-list>
              </v-menu>
              
              <template v-if="header.text === 'Actions'">
                <v-btn
                  @click="toggleAllCandidat = !toggleAllCandidat; toggleAllSelectedCandidate(toggleAllCandidat)"
                  icon
                >
                  <v-icon v-if="!toggleAllCandidat" >mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
            </th>
          </tr>
        </thead>
      </template>

      <!--Couleur du candidat-->
      <template v-slot:item="{ item }">
        <tr :class="rowColor(item)">
          <td>{{ item.trigrammeTAC }}</td>
          <td>{{ item.nom }}</td>
          <td>{{ item.prenom }}</td>
          <td>{{ dateInText(item.disponibilite) }}</td>
          <!-- <td>
            <v-icon v-if="isASAP(item.disponibilite)">mdi-check</v-icon>
          </td> -->
          <td>{{ item.profilFCT }}</td>
          <td>{{ item.type_ecole }}</td>
          <td>
            <v-chip :color="chipColor(item)">
              {{ item.statut }}
            </v-chip>
          </td>
          <td>{{ dateEnToFr(item.prequa_tel) }}</td>
          <td>{{ item.label_entite }}</td>
          <td>{{ item.pole }}</td>
          <td>{{ item.source_chasse }}</td>
          <td>{{ item.label_contrat }}</td>
          <td v-if="item.cooptation === 1"> ✔ </td>
          <td v-else></td>
          <!-- <td><v-icon v-if="item.cooptation === 1">mdi-check</v-icon></td> -->
          <td v-if="item.is_linkedin === 1"> ✔ </td>
          <td v-else> </td>

          <!--Bouton de gestion du candidat-->
          <td>
            <v-menu rounded="xl" left offset-x>
              <template v-slot:activator="{ on, attrs }">
                <div style="display: flex; align-items: center;">
                  <v-btn v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(5)"  v-bind="attrs" v-on="on" icon>
                    <v-icon >mdi-dots-vertical</v-icon>
                  </v-btn>
                  <v-icon v-else>mdi-link-lock</v-icon>
                  <!-- <v-btn icon @click="$refs.infordv.showInfosRDV(item)">
                  <v-icon>mdi-calendar</v-icon>
                </v-btn> -->
                  <v-btn icon @click="toggleSelectedCandidate(item.id)">
                    <v-icon v-if="selectedCandidates.includes(item.id)">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </template>

              <v-list rounded dense>
                <v-list-item @click="$refs.infoscandidat.showInfos(item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-information</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Informations</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.updatecandidat.openDialog(item.id, typeCandidat, item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Modifier</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.updaterdv.openDialog(item.id, typeCandidat, item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-calendar-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Modifier les RDV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.delcandidat.openDialog(item.id, typeCandidat)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <tr v-if="selectedCandidates.includes(item.id)">
          <td colspan="12">
            <div v-if="item.rdvList.length > 0">
              <table>
                <tr v-for="rdv in item.rdvList">
                  <v-chip :color="chipRdvColor(rdv)">
                    <td> <span class="rdv-type">{{ rdv.rdvType }}</span></td>
                  </v-chip>
                  <td class="date-col"><strong>Date :</strong> <span class="date-frame">{{ rdv.date }}</span></td>
                  <td class="trigramme-col"><strong>Trigramme :</strong> <span class="trigramme-frame">{{ rdv.trigramme
                  }}</span></td>
                  <td class="comment-col">
                    <strong v-if="rdv.rdvType !== 'Offre'">Commentaire :</strong>
                    <span class="comment-frame" v-if="rdv.rdvType !== 'Offre'"><strong>{{ rdv.commentaire
                    }}</strong></span>
                    <span class="comment-frame" v-else><strong>Date de démarrage :</strong> {{ rdv.commentaire }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <v-chip color="red lighten-4"> Aucun RDV programmé pour ce candidat !</v-chip>
            </div>
          </td>
        </tr>


      </template>
    </v-data-table>
  </v-card>
</template>
  
<script>
import InfosCandidat from "../components/InfosCandidat";
import infordv from "../components/infordv";
import AddCandidat from "../components/form/AddCandidat";
import DelCandidat from "../components/form/DelCandidat";
import UpdateCandidat from "../components/form/UpdateCandidat";
import UpdateRDV from "../components/form/UpdateRDV";
import AddCandidatButton from '../components/AddCandidatButton';
import { getCandidats } from "../api/candidats";
import { candidatFCTHeaders } from "../TabHeaders/candidatFCTHeaders.js";
import { candidatsFCTFilters } from "../components/filters/candidatsFCTFilters.js";
import { rowColor, chipColor, chipRdvColor } from "../commonFunction/statusColors";
import { dateEnToFr, dateInText } from "../commonFunction/date";
import { getChamps } from "../api/champs";
import { eventBus } from '../api/eventBus'
import { exportToGSheet } from '../api/export'

export default {
  components: {
    InfosCandidat,
    infordv,
    DelCandidat,
    AddCandidat,
    AddCandidatButton,
    UpdateCandidat,
    UpdateRDV
  },
  data: () => ({
    typeCandidat: "fct",
    toggle: "FCT",
    item: {}, //Variable de stockage de l'item du tableau
    headers: [], //Lien entre les noms de colonnes et les données
    search: "",
    exporting: false,
    selectedFilter: "",
    selectedFilterChip: "",
    candidatsFilters: [],
    selectedCandidates: [],
    candidats: [],
    // rdvList: [],
    filters: {},
    is_rdv1: 0,
    is_rdv2: 0,
    is_rdv3: 0,
    is_rdv4: 0,
    is_offre: 0,
    is_sans_rdv: 0,
    page_size: 250,
    loading: false,
    totalItems: 0,
    page: 1,
    loading: false,
    showChipMenu: false,
    showListMenu: false,
    showRDV: false,
    selectedCandidate: null,
    inactiveFilters: [],
    key: 0,
    tableKey: 0,
    tacSearch: null,
    toggleAllCandidat: false,
    profilSearch: null
  }),
  created() {
    this.headers = candidatFCTHeaders;
    this.candidatsFilters = candidatsFCTFilters
    this.inactiveFilters = candidatsFCTFilters
  },
  async mounted() {
    this.fetchCandidats()
    this.$store.commit("SET_CHAMPS", await getChamps())
    eventBus.$on('candidatAdded', async () => {
      await this.fetchCandidats()
      this.toggleAllSelectedCandidate(this.toggleAllCandidat)
    })
  },
  computed: {
    filteredTAC() { 
      if (this.tacSearch) { 
        const searchRegex = new RegExp(this.tacSearch, 'i'); 
        const filtered = this.champs.TAC.filter(tac => searchRegex.test(tac)); 
        return filtered; 
      } else { 
        return this.champs.TAC; 
      } 
    },
    filteredProfil() { 
      if (this.profilSearch) { 
        const searchRegex = new RegExp(this.profilSearch, 'i'); 
        const filtered = this.champs.profilsFCT.filter(profil => searchRegex.test(profil)); 
        return filtered; 
      } else { 
        return this.champs.profilsFCT; 
      }
    },
    shownFilterIcons() {
      return (header) => {
        const showIcon = this.showFilterIcon(header).length > 0;
        const showVariantIcon = this.showFilterIcon(header).length === 1 && !this.curentHeaderFilters(header).length > 0;
        return showVariantIcon ? 'mdi-filter-variant' : showIcon ? 'mdi-filter' : '';
      };
    },
    userRoles() {
      return this.$store.getters.currentRoles;
    },
    champs() {
      return this.$store.getters.champs
    },
    activeFilters() {
      if (!this.showChipMenu) {
        this.key += 1
      }
      return this.candidatsFilters.filter((f) => {
        this.filters = {
          type: "fct",
          page: this.page,
          page_size: this.page_size,
          search: this.search,
          trigrammeTAC: this.candidatsFilters[0].selected,
          disponibilite: this.candidatsFilters[1].selected.map(d => d + "-01"),
          profilFCT: this.candidatsFilters[2].selected,
          type_ecole: this.candidatsFilters[3].selected,
          statut: this.candidatsFilters[4].selected,
          prequa_tel: this.candidatsFilters[5].selected,
          label_entite: this.candidatsFilters[6].selected,
          pole: this.candidatsFilters[7].selected,
          label_contrat: this.candidatsFilters[8].selected,
          source_chasse: this.candidatsFilters[9].selected,
          cooptation: this.candidatsFilters[10].selected,
          is_linkedin: this.candidatsFilters[11].selected,
          is_rdv1: this.is_rdv1,
          is_rdv2: this.is_rdv2,
          is_rdv3: this.is_rdv3,
          is_rdv4: this.is_rdv4,
          is_offre: this.is_offre,
          is_sans_rdv: this.is_sans_rdv
        }
        return f.selected.length > 0
      })
    },
  },

  methods: {
    resetFilter() {
      this.fetchCandidats();
    },
    getBackgroundColor(type) {
      switch (type) {
        case 'RDV2':
          return '#FFFF00'; // Couleur jaune pour RDV2
        case 'RDV3':
          return '#FFA500'; // Couleur orange pour RDV3
        case 'Offre':
          return '#00CED1'; // Couleur bleu turquoise pour offre
        default:
          return '';
      }
    },
    async exportData() {
      try {
        this.exporting = true; 
        const email = this.$store.getters.user.email;
        const result = await exportToGSheet(email, this.filters)

        window.open(result.SheetURL, "_blank");
        
        //console.log('Exportation terminée!');
      } catch (error) {
        //console.error('Erreur lors de l\'exportation vers Google sheet', error);
      } finally {
        this.exporting = false; 
      }
    },
    showFilterIcon(header) {
      return this.candidatsFilters.filter(elt => elt.value === header);
    },
    curentHeaderFilters(header) {
      return this.candidatsFilters.filter(elt => elt.value == header && elt.selected.length > 0)
    },



    async fetchCandidats() {
      this.loading = true;

      // Sauvegarder la page actuelle avant de fetch les candidats
      const currentPage = this.page;

      await getCandidats(this.filters).then((response) => {
        this.candidats = response.items.map((elt)=> this.addRDVListToCandidate(elt));
        this.totalItems = response.total;

        // Vérifier si la page actuelle est toujours valide après le filtrage
        if (currentPage > Math.ceil(this.totalItems / this.page_size)) {
      // Si la page actuelle n'est plus valide, revenir à la première page
      this.page = 1;
    }
        this.loading = false;
      });
    },



    // async fetchCandidatsASAP() {
    //   this.loading = true;
    //   await getCandidats(this.filters).then((response) => {
    //     if (this.asapActive) {
    //       const currentDate = new Date();
    //       this.candidats = response.items.filter((candidat) => {
    //         const candidateDate = new Date(candidat.disponibilite);
    //         const timeDifference = candidateDate.getTime() - currentDate.getTime();
    //         const daysDifference = timeDifference / (1000 * 3600 * 24);
    //         return daysDifference >= 0 && daysDifference <= 30;
    //       });
    //     } else {
    //       this.candidats = response.items;
    //     }
    //     this.totalItems = this.candidats.length;
    //     this.loading = false;
    //   });
    // },
    handleDateRangeChange() {
      const selectedDates = this.candidatsFilters[5].selected;

      const startDate = new Date(selectedDates[0]);
      const endDate = new Date(selectedDates[selectedDates.length - 1]);
      const allDates = this.getDatesBetween(startDate, endDate);

      this.candidatsFilters[5].selected = allDates;

      this.fetchCandidats();
    },

    getDatesBetween(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    },
    // toggleASAP() {
    //   this.asapActive = !this.asapActive;
    //   // Réexécutez la méthode fetchCandidats pour mettre à jour la liste des candidats
    //   this.fetchCandidatsASAP();
    // },
    // async fetchSansRDV() {
    //   this.loading = true;

    //   if (this.is_sans_rdv) {
    //     // Fetch initial list of candidates
    //     await this.fetchCandidats();
    //   } else {
    //     // Fetch candidates without appointments
    //     await getCandidats(this.filters).then((response) => {
    //       this.candidats = response.items;
    //       this.totalItems = response.total;
    //       this.loading = false;

    //       this.candidats = this.candidats.filter((candidat) => {
    //         return (
    //           candidat.rdv1 === null &&
    //           candidat.rdv2 === null &&
    //           candidat.rdv3 === null &&
    //           candidat.rdv4 === null &&
    //           candidat.offre === null
    //         );
    //       });
    //     });
    //   }
    // },

    // isASAP(disponibilite) {
    //   const currentDate = new Date();
    //   const candidateDate = new Date(disponibilite);

    //   const timeDifference = candidateDate.getTime() - currentDate.getTime();
    //   const daysDifference = timeDifference / (1000 * 3600 * 24);

    //   // Vérifiez si la disponibilité est dans moins de 30 jours
    //   return daysDifference <= 30 && daysDifference > 0;
    // },
    // toggleChasseFilter() {
    //   this.showChasseFilter = !this.showChasseFilter;

    //   if (this.showChasseFilter) {
    //     this.filters.source_chasse = ["chasse"];
    //   } else {
    //     this.filters.source_chasse = [];
    //   }

    //   this.fetchCandidats();
    // },

    applyPoleFilter() {
      //this.filters.pole = this.selectedPole;
      if (this.showChasseFilter) {
        this.filters.source_chasse = ["Chasse"];
      } else {
        this.filters.source_chasse = []; // Désactivez le filtre
      }



      this.fetchCandidats();
    },

    clearDate(fieldIndex) {
      const selectedDates = this.candidatsFilters[fieldIndex].selected;
      this.candidatsFilters[fieldIndex].selected = selectedDates.filter((item) => item == null);
    },

    removeFilter(filter) {
      for (let i = 0; i < this.candidatsFilters.length; i++) {
        if (this.candidatsFilters[i].value == filter) {
          this.candidatsFilters[i].selected = []
        }
      }
    },
    updateFilters() {
      //console.log("updateFilters triggered")
      this.inactiveFilters = this.candidatsFilters.filter((f) => {
        return f.selected.length == 0
      })

    },
    changeToggle() {
      this.$router.push('/candidats/ope')
    },
    // showRDVtab(candidat) {
    //   candidat.rdvList = []; // Réinitialiser la liste des RDV à chaque fois que la fonction est appelée
    //   if (candidat.rdv1) {
    //     candidat.rdvList.push({
    //       rdvType: 'RDV1',
    //       date: this.dateEnToFrComponent(candidat.rdv1),
    //       trigramme: candidat.trigramme1,
    //       commentaire: candidat.commentaire_rdv1 || 'Aucun commentaire'
    //     });
    //   }
    //   if (candidat.rdv2) {
    //     candidat.rdvList.push({
    //       rdvType: 'RDV2',
    //       date: this.dateEnToFrComponent(candidat.rdv2),
    //       trigramme: candidat.trigramme2,
    //       commentaire: candidat.commentaire_rdv2 || 'Aucun commentaire'
    //     });
    //   }
    //   if (candidat.rdv3) {
    //     candidat.rdvList.push({
    //       rdvType: 'RDV3',
    //       date: this.dateEnToFrComponent(candidat.rdv3),
    //       trigramme: candidat.trigramme3,
    //       commentaire: candidat.commentaire_rdv3 || 'Aucun commentaire'
    //     });
    //   }
    //   if (candidat.rdv4) {
    //     candidat.rdvList.push({
    //       rdvType: 'RDV4',
    //       date: this.dateEnToFrComponent(candidat.rdv4),
    //       trigramme: candidat.trigramme4,
    //       commentaire: candidat.commentaire_rdv4 || 'Aucun commentaire'
    //     });
    //   }
    //   if (candidat.offre) {
    //     candidat.rdvList.push({
    //       rdvType: 'Offre',
    //       date: this.dateEnToFrComponent(candidat.offre),
    //       trigramme: candidat.resp_offre,
    //       commentaire: candidat.date_demarrage || 'Aucun commentaire'
    //     });
    //   }
    // },
    addRDVListToCandidate(candidat) {
      // Initialize or clear the rdvList array
      candidat.rdvList = [];

      const rdvTypes = ['rdv1', 'rdv2', 'rdv3', 'rdv4'];
      
      rdvTypes.forEach((rdvType, index) => {
        const rdvDate = candidat[rdvType];
        if (rdvDate) {
          const trigrammeKey = `trigramme${index + 1}`;
          const commentaireKey = `commentaire_${rdvType}`;
          
          candidat.rdvList.push({
            rdvType: `RDV${index + 1}`,
            date: this.dateEnToFrComponent(rdvDate),
            trigramme: candidat[trigrammeKey],
            commentaire: candidat[commentaireKey] || 'Aucun commentaire',
          });
        }
      });

      // Check for 'offre'
      if (candidat.offre) {
        candidat.rdvList.push({
          rdvType: 'Offre',
          date: this.dateEnToFrComponent(candidat.offre),
          trigramme: candidat.resp_offre,
          commentaire: candidat.date_demarrage || 'Aucun commentaire',
        });
      }

      return candidat;
    },
    getRdvTypeClass(rdvType) {
      switch (rdvType) {
        case 'RDV1':
          return 'rdv-type-rdv1';
        case 'RDV2':
          return 'rdv-type-rdv2';
        case 'RDV3':
          return 'rdv-type-rdv3';
        case 'RDV4':
          return 'rdv-type-rdv4';
        case 'Offre':
          return 'rdv-type-offre';
        default:
          return 'rdv-type-default';
      }
    },
    dateEnToFrComponent(dateString) {
      return dateEnToFr(dateString);
    },
    dateInTextComponent(dateString) {
      return dateInText(dateString);
    },

    toggleAllSelectedCandidate(toggle){
      if(toggle){
        this.selectedCandidates = this.candidats.map((element) => element.id)
      }
      else {
        this.selectedCandidates = []
      }
    },

    toggleSelectedCandidate(candidateId) {
      const index = this.selectedCandidates.indexOf(candidateId);
      if (index === -1) {
        this.selectedCandidates.push(candidateId); // Ajoutez le candidat à la liste des candidats sélectionnés
      } else {
        this.selectedCandidates.splice(index, 1); // Retirez le candidat de la liste des candidats sélectionnés
      }
    },
    dateEnToFr,
    dateInText,
    rowColor,
    chipColor,
    chipRdvColor
  },
  watch: {
    showListMenu(newValue) {
      // Actutaliser les filtres des candidats quand on ferme le menu déroulant
      if (newValue == false) {
        this.updateFilters()
      }
    },
    showChipMenu(newValue) {
      // Actutaliser les filtres des candidats quand on ferme une chip
      if (newValue == false) {
        this.updateFilters()
      }
    },
    filters() {
      this.fetchCandidats()
    }
  }
};
</script>

<style scoped>
td {
  border: none;
  text-align: center;
}

ul {
  list-style-type: none;
  text-align: center;
}

.pos-button {
  position: relative;
  top: -4px;
  left: 19px;
}

.floating-export-btn {
  position: fixed;
  bottom: 70px;
  left: 70px;
  z-index: 1;
}

.custom-text {
  color: black;
  /* Couleur du texte en noir */
  text-align: center;
  /* Centrer le texte horizontalement */
  display: block;
  /* Assure que le texte occupe toute la largeur disponible */
}

.custom-btn {
  background-color: #1b6b9a;
  color: rgb(3, 3, 3);
  border: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.custom-btn:hover {
  background-color: #bbd0e2bd;
}

.rdv-type-col {
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

.date-col,
.trigramme-col,
.comment-col {
  padding: 10px;
  text-align: left;
  vertical-align: top;

}

.rdv-type-col,
.date-col,
.trigramme-col {
  width: 200px;
}

.comment-col {
  width: auto;
}

.rdv-type-rdv1 {
  background-color: #f2f3f3;
  /* Red background for RDV1 */
}

.rdv-type-rdv2 {
  background-color: #fffda7;
  /* Green background for RDV2 */
}

.rdv-type-rdv3 {
  background-color: #ffe0b2;
  /* Blue background for RDV3 */
}

.rdv-type-rdv4 {
  background-color: #f2f3f3;
  /* Purple background for RDV4 */
}

.rdv-type-offre {
  background-color: #b2ebf2;
  /* Yellow background for Offre */
}</style>